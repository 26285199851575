import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LibraryMusic from "../images/svg/library.music.inline.svg"
import PlaylistMusic from "../images/svg/playlist.music.inline.svg"
import PhotoLibrary from "../images/svg/photo.library.inline.svg"
import Img from "gatsby-image"
import { FaPalette } from "react-icons/fa"

const Features = () => {

    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "features-1-images.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      playlistImage: file(relativePath: { eq: "features-2-images.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
        <section className="features section bd-container" id="features">
            <div className="features__container bd-grid">
                <div className="features__data">
                    <h2 className="section-title features__initial" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">Listen music and customize <br></br>your player theme</h2>
                    <p className="features__description" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">In our app, you can listen music from our smart playlists features and customize now playling screen with themes that are available now.</p>
                    <div className="features-item left-pane top" data-sal="slide-down" data-sal-delay="300" data-sal-duration="400" data-sal-easing="ease">
                        <div className="features-body">
                            <div className="features-item icon">
                                <i className="features-item icon light-green fas"><FaPalette className="green"/></i>
                            </div>
                            <div className="text-wrapper">
                                <div className="title">Customize Now Playing Screen</div>
                                <p className="subtitle">You can customize now playing screen based on your favorites theme. More themes will coming in the future.</p>
                            </div>
                        </div>
                    </div>

                    <div className="features-item left-pane top" data-sal="slide-down" data-sal-delay="400" data-sal-duration="400" data-sal-easing="ease">
                        <div className="features-body">
                            <div className="features-item icon">
                                <i className="features-item icon light-red fas"><LibraryMusic className="red"/></i>
                            </div>
                            <div className="text-wrapper">
                                <div className="title">Smart Playlists</div>
                                <p className="subtitle">You can play music from recently added songs, recently played, recent albums & recent artists that automatically created from our smart playlists features.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Img fluid={data.placeholderImage.childImageSharp.fluid} className="features__img" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease"/>
            </div>
            <div className="features__container bd-grid">
                <div className="features__data">
                    <h2 className="section-title features__initial" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">Create and organized<br></br>your playlists</h2>
                    <p className="features__description" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">In our app, you can create and organized your playlists easily. You can also change the playlist cover. Try this feature today.</p>
                    <div className="features-item left-pane top" data-sal="slide-down" data-sal-delay="300" data-sal-duration="400" data-sal-easing="ease">
                        <div className="features-body">
                            <div className="features-item icon">
                                <i className="features-item icon orange fas"><PlaylistMusic className="white"/></i>
                            </div>
                            <div className="text-wrapper">
                                <div className="title">Easy to reorder your music in playlist</div>
                                <p className="subtitle">You can easily reoder your music with drag & drop feature and also swipe to delete music. These will help you easily organized your playlists.</p>
                            </div>
                        </div>
                    </div>

                    <div className="features-item left-pane top" data-sal="slide-down" data-sal-delay="400" data-sal-duration="400" data-sal-easing="ease">
                        <div className="features-body">
                            <div className="features-item icon">
                                <i className="features-item icon blue fas"><PhotoLibrary className="red"/></i>
                            </div>
                            <div className="text-wrapper">
                                <div className="title">Set cover for your playlists</div>
                                <p className="subtitle">You can change your playlists cover based on your interest and style.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Img fluid={data.playlistImage.childImageSharp.fluid} className="features__img_right" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease"/>
            </div>
        </section>
    )

}

 
export default Features