import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Illustration from "../images/svg/img.header.inline.svg"
import { FaGooglePlay } from "react-icons/fa"
import Img from "gatsby-image"

const Header = () => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "img-header-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="home" id="home">
      <div className="home__container bd-container bd-grid">
        <div className="home__data" data-sal="slide-right" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">
          <h1 className="home__title">Listen to your <br></br> favorites songs <br></br> with <a className="home__logo-color">Musix</a>Mize</h1>
          <p className="home__description">MusixMize is an offline music player app that gives you ability to customize player based on your favorites themes.</p>
          {/* <a href="/#contact" className="button"><FaGooglePlay className="fab fa-google-play"/>Coming Soon</a> */}
          <a href="https://play.google.com/store/apps/details?id=com.musixmize.android" className="button" target="_blank" rel="noopener noreferrer"><FaGooglePlay className="fab fa-google-play"/>Download</a>
        </div>

      
        {/* <Illustration className="home__img" data-sal="slide-left" data-sal-delay="300" data-sal-duration="400" data-sal-easing="ease"/>    */}
        <Img fluid={data.placeholderImage.childImageSharp.fluid} className="home__img" data-sal="slide-down" data-sal-delay="300" data-sal-duration="400" data-sal-easing="ease"/>
      </div>
    </section>
  )

}

export default Header
