import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaGooglePlay } from "react-icons/fa"

const Download = () => {

    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "homescreen-images.png" }) {
                childImageSharp {
                    fluid(maxWidth: 4096) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }   
    `)

    return (
        <section className="download section bd-container" id="download">
            <div className="download__container bd-grid">
                <Img fluid={data.placeholderImage.childImageSharp.fluid} className="download__img"/>
                <div className="download__data" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">
                    <h2 className="section-title download__title">Try the latest version of our app</h2>
                    <p className="download__description">Try MusixMize app we are sure that it will expand the boundaries of your musical taste. Download the app now on Google Play Store.</p>
                    {/* <a href="/#contact" className="button-white"><FaGooglePlay className="fab fa-google-play"/>Coming Soon</a> */}
                    <a href="https://play.google.com/store/apps/details?id=com.musixmize.android" className="button-white" target="_blank" rel="noopener noreferrer"><FaGooglePlay className="fab fa-google-play"/>Download</a>
                </div>
            </div>
        </section>
    )
}

export default Download